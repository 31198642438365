import React from 'react'
import { Box } from 'components'
import styled from '@emotion/styled'
import { T, __ } from 'translations/i18n'

export default function RoundedLabel({
  label,
  value = '---',
  style,
  maxWidth,
}: {
  label?: string
  value?: string
  style?: React.CSSProperties
  maxWidth?: number
}) {
  const getColor = () => {
    switch (value.toLowerCase()) {
      case __(T.misc.unknown_product).toLowerCase():
      case __(T.misc.unknown_tag).toLowerCase():
        return { backgroundColor: '#FFEFC6', color: '#D6A52A' }
      case __(T.misc.invalid_tag).toLowerCase():
        return { backgroundColor: '#FFD4CB', color: '#EF4141' }
      case __(T.misc.stock).toLowerCase():
        return { backgroundColor: '#C6EAFF', color: '#3796ED' }
      case __(T.misc.valid).toLowerCase():
        return { backgroundColor: '#99FEC5', color: '#1BBA60' }

      default:
        return { backgroundColor: '#EDEDED' }
    }
  }

  if (value === '---') return <></>

  return (
    <Box
      row
      mr={15}
      style={{
        borderRadius: 5,
        backgroundColor: getColor().backgroundColor,
        maxWidth: maxWidth,
        ...style,
      }}
      pv={5}
      ph={10}
    >
      <SmallLabel>{label?.toUpperCase()}</SmallLabel>
      {value && <SmallValue style={{ color: getColor().color }}>{value ?? ' ---'}</SmallValue>}
    </Box>
  )
}

const SmallValue = styled.label`
  font-weight: 900;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
const SmallLabel = styled.label`
  font-weight: 400;
  font-size: 16px;
  white-space: nowrap;
  margin-right: 5px;
`
