import React, { Component } from 'react'
import styled from '@emotion/styled'

const OptionsContainer = styled.div`
  height: 77px;
  background: #ffffff;
  border-radius: 100px;
  padding: 0px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const OptionButton = styled.div<{ active: boolean }>`
  display: flex;
  width: 210px;
  height: 57px;
  border-radius: 100px;
  background-color: ${(props) => (props.active ? props.theme.primary : 'white')};
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

interface Props {
  options: any[]
  onOptionSelected: (option: string) => void
}

export default class Tab extends Component<Props> {
  render() {
    const { options, onOptionSelected } = this.props
    if (!options || options.length === 0) {
      return <></>
    }

    return (
      <OptionsContainer>
        {options.map((option, index) => (
          <OptionButton key={`option-${index}`} active={option.active} onClick={() => onOptionSelected(option.value)}>
            {option.label}
          </OptionButton>
        ))}
      </OptionsContainer>
    )
  }
}
