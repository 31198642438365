import React, { Component } from 'react'
import { StatusLabel } from 'components'
import { T, __ } from 'translations/i18n'

interface Props {
  status: string
  width?: number
  type?: 'inbound' | 'outbound'
}

export default class ParcelStatus extends Component<Props> {
  getStatusLabelText = (status: string): string => {
    const statuses = {
      DRAFT: __(T.labels.draft),
      READY_TO_SEND: __(T.labels.ready_to_send),
      SEND_AWAIT: __(T.labels.send_await),
      OUTBOUND_ERROR: __(T.labels.outbound_error),
      SENT: __(T.labels.sent),
      IN_TRANSIT: __(T.labels.sent),
      IN_INBOUND: __(T.labels.in_inbound),
      RECEIVE_AWAIT: __(T.labels.receive_await),
      READY_TO_RECEIVE: __(T.labels.ready_to_receive),
      INBOUND_ERROR: __(T.labels.inbound_error),
      RECEIVED: __(T.labels.received),
    }

    return statuses[status] ?? status
  }

  render() {
    const { status, width } = this.props
    const text = this.getStatusLabelText(status)

    return <StatusLabel width={width} status={status} text={text.toUpperCase()} />
  }
}
