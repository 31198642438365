import React from 'react'
import { isNumber } from 'shared/utils'
import styled from '@emotion/styled'
import { AnimatePresence, motion, MotionStyle, Transition } from 'framer-motion'
import { fadeConfig, slideFadeConfig } from './BoxAnimations'

export interface BoxProps {
  flex?: number | boolean
  row?: boolean
  center?: boolean
  vcenter?: boolean
  hcenter?: boolean
  m?: number // margin
  mv?: number // margin vertical
  mh?: number // margin horizontal
  mt?: number // margin top
  mb?: number // margin bottom
  mr?: number // margin right
  ml?: number // margin left
  p?: number // padding
  pv?: number // padding vertical
  ph?: number // padding horizontal
  pt?: number // padding top
  pb?: number // padding bottom
  pr?: number // padding right
  pl?: number // padding left
  bgGrey?: boolean
  height?: string | number
  width?: string | number
  hideBar?: boolean
  onClick?: (item?: any) => void
  children?: React.ReactNode
  style?: MotionStyle
  animation?: 'fade' | 'slide'
  transition?: Transition
  id?: any
}

export default function Box(props: BoxProps) {
  const getAnimation = () => {
    switch (props.animation) {
      case 'fade':
        return fadeConfig
      case 'slide':
        return slideFadeConfig
      default:
        return {}
    }
  }
  return (
    <AnimatePresence key={props.id} custom={{ offsetX: 0, offsetY: 8, reverse: true }}>
      <StyledBox key={props.id} id={props.id} {...props} {...getAnimation()} />
    </AnimatePresence>
  )
}

function flexIfDefined(flex: number | boolean | undefined) {
  if (flex === undefined) return undefined
  if (isNumber(flex)) return flex as number
  return 1
}

const StyledBox = styled(motion.div)<BoxProps>`
  flex: ${({ flex }) => flexIfDefined(flex)};
  flex-shrink: 0;
  /* flex-basis: auto; */
  display: -webkit-flex;
  ${({ hideBar }) =>
    hideBar &&
    `-ms-overflow-style: none;  /* Internet Explorer 10+ */
     scrollbar-width: none;  /* Firefox */
     ::-webkit-scrollbar { 
       display: none; 
   }
   `}
  flex-direction: ${({ row }) => (row ? 'row' : 'column')};
  ${({ hcenter, row }) => hcenter && (row ? 'justify-content:center;' : 'align-items:center;')}
  ${({ vcenter, row }) => vcenter && (row ? 'align-items:center;' : 'justify-content:center;')}
   ${({ center }) => center && 'justify-content:center; align-items:center;'}
   ${({ mv }) => isNumber(mv) && `margin-top: ${mv}px!important; margin-bottom: ${mv}px!important;`}
   ${({ mh }) => isNumber(mh) && `margin-left: ${mh}px!important; margin-right: ${mh}px!important;`}
   ${({ mt }) => isNumber(mt) && `margin-top: ${mt}px!important;`}
   ${({ mb }) => isNumber(mb) && `margin-bottom: ${mb}px!important;`}
   ${({ mr }) => isNumber(mr) && `margin-right: ${mr}px!important;`}
   ${({ ml }) => isNumber(ml) && `margin-left: ${ml}px!important;`}
   ${({ m }) => isNumber(m) && `margin: ${m}px;`}
   ${({ pv }) => isNumber(pv) && `padding-top: ${pv}px!important; padding-bottom: ${pv}px!important;`}
   ${({ ph }) => isNumber(ph) && `padding-left: ${ph}px!important; padding-right: ${ph}px!important;`}
   ${({ pt }) => isNumber(pt) && `padding-top: ${pt}px!important;`}
   ${({ pb }) => isNumber(pb) && `padding-bottom: ${pb}px!important;`}
   ${({ pr }) => isNumber(pr) && `padding-right: ${pr}px!important;`}
   ${({ pl }) => isNumber(pl) && `padding-left: ${pl}px!important;`}
   ${({ p }) => isNumber(p) && `padding: ${p}px;`}
   ${({ bgGrey, theme }) => bgGrey && `background-color: ${theme.background1};`}
   ${({ height }) => height !== undefined && `height: ${typeof height === 'number' ? `${height}px` : height};`}
   ${({ width }) => width !== undefined && `width: ${typeof width === 'number' ? `${width}px` : width};`}
   ${({ width }) => width !== undefined && `min-width: ${typeof width === 'number' ? `${width}px` : width};`}
   ${({ onClick }) => !!onClick && `cursor: pointer;`}
`
