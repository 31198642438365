import { ConfirmModalParams, ModalType, TmrUser, TmrWorkstation, TmrZone } from 'api/types'
import Auth from 'api/Auth'
import Storage from 'shared/Storage'
import { showToast, __isDev } from 'shared/utils'
import { reloadApp } from 'shared/router'
import Zones from 'api/Zones'
import api from 'api/api'
import { changeLanguage as changeLanguage_i18n, T, __ } from 'translations/i18n'

export interface Emulation {
  name: string
  value: string
}

class AppStore {
  authToken?: string

  defaultWorkstation?: TmrWorkstation

  workstations?: TmrWorkstation[]

  loggedUser?: TmrUser

  zones?: TmrZone[]

  language = 'it'

  languages = ['it', 'en']

  emulation = __isDev

  dailyItems = 0

  emulationList: Emulation[] = []

  scanners: string[] = []

  confirmModalParams: ConfirmModalParams = {
    title: '',
    message: '',
    onConfirm: () => {},
    onCancel: () => {},
  }
  toggleConfirmModalHandler?: (show: boolean) => void

  openModal?: (modal: ModalType) => void
  hideModal?: (id: string) => void

  reloadRouting!: () => void

  async loadInitalData() {
    //check language
    this.language = await Storage.load('language', 'it')
    changeLanguage_i18n(this.language)

    //check if is present a custom endpoint
    api.setBaseURL(await Storage.load('endpoint', api.getBaseURL()))

    await this.loadAuthToken()
    if (this.authToken) {
      try {
        this.loggedUser = await Auth.loggedUser()
        if (this.loggedUser) {
          this.zones = await Zones.search({
            'place.id': this.loggedUser.place.id,
          })
        }
        this.scanners = this.loggedUser?.place.attributes?.scanners.replace(/ /g, '').split(',') ?? []
      } catch (err) {
        showToast({
          title: __(T.error.error),
          description: err?.message ?? 'Check capabilities',
          status: 'error',
        })
        this.logout()
        throw err
      }
    }
  }

  async getEndpoint() {
    return Storage.load('endpoint', api.getBaseURL())
  }

  async changeLanguage(language: string) {
    this.language = language
    await Storage.save('language', language)
    changeLanguage_i18n(language)
  }

  async setEndpoint(endpoint) {
    await Storage.save('endpoint', endpoint)
    reloadApp()
  }

  getZoneByType(type: 'STOCK' | 'SOLD' | 'INBOUND' | 'LOST') {
    if (!this.zones) return undefined

    return this.zones.find((zone) => zone.zoneType === type)
  }

  async enableEmulation(emulation = true) {
    this.emulation = emulation
    await Storage.save(`emulation`, emulation)
  }

  async increaseDailyItems() {
    this.dailyItems++
    await Storage.save(`dailyItems`, this.dailyItems)
  }

  async resetDailyItems() {
    this.dailyItems = 0
    await Storage.save(`dailyItems`, 0)
  }

  async saveDefaultWorkstation(workstation: TmrWorkstation) {
    this.defaultWorkstation = workstation
    await Storage.save(`defaultWorkstation`, workstation)
    return this.defaultWorkstation
  }

  async getDefaultWorkstation() {
    this.defaultWorkstation = await Storage.load(`defaultWorkstation`, this.defaultWorkstation)
    return this.defaultWorkstation
  }

  async saveAuthToken(token?: string) {
    this.authToken = token
    await Storage.save(`authToken`, token)
    return this.authToken
  }

  async logout() {
    // await Auth.logout()
    Storage.remove(`authToken`)
    this.authToken = undefined
    Storage.remove(`defaultWorkstation`)
    this.defaultWorkstation = undefined
    Storage.remove(`defaultPrinter`)
    Storage.remove(`dailyItems`)
    Storage.remove(`emulation`)
    this.loggedUser = undefined
    if (this.reloadRouting) this.reloadRouting()
    else reloadApp()
  }

  async loadAuthToken() {
    this.authToken = await Storage.load(`authToken`, this.authToken)
    Auth.setHeaderAccessToken(this.authToken!)
    return this.authToken
  }

  async saveEmulationList(emulationList: Emulation[]) {
    this.emulationList = emulationList
    await Storage.save(`emulationList`, emulationList)
    return this.emulationList
  }

  async loadEmulationList() {
    this.emulationList = await Storage.load(`emulationList`, this.emulationList)
    return this.emulationList
  }

  setConfirmModalHandler(handler) {
    this.toggleConfirmModalHandler = handler
  }

  showConfirmModal(title: string, message: string, onConfirm: () => void, onCancel?: () => void) {
    this.confirmModalParams = {
      title,
      message,
      onConfirm,
      onCancel: onCancel ?? undefined,
    }
    this.toggleConfirmModalHandler?.(true)
  }

  hideConfirmModal() {
    this.toggleConfirmModalHandler?.(false)
  }
}

export default new AppStore()
