import { OperationConfig } from 'api/types'

export const RegisteredRoutes = {
  '/': { component: require('./Dashboard').default },
}

export const PublicRoutes = {
  '/login': { component: require('./Login').default },
}

export type Routes = keyof typeof RegisteredRoutes | keyof typeof PublicRoutes

export interface PageParams {
  operation?: OperationConfig
}
