import React from 'react'
import { Form, Page } from 'components'
import Auth from 'api/Auth'
import { Container, RightContainer } from 'components/Various'
import { T, __ } from 'translations/i18n'
import { showToast } from 'shared/utils'
import { reloadApp } from 'shared/router'
import styled from '@emotion/styled'

export default function Login() {
  const onLogin = async (data: any) => {
    try {
      await Auth.login(data.username, data.password)
      reloadApp()
    } catch (error) {
      showToast({ title: 'Autenticazione fallita', status: 'error' })
    }
  }

  return (
    <Page title="Login" header={null}>
      <Container>
        <RightContainer>
          <TitleStyle>Cucinelli Desk Notice</TitleStyle>
          <Form
            style={{ maxWidth: 330 }}
            schema={[
              {
                placeholder: __(T.misc.username),
                name: 'username',
                required: true,
                focus: true,
              },
              {
                placeholder: __(T.misc.password),
                name: 'password',
                type: 'password',
                required: true,
              },
            ]}
            onSubmit={onLogin}
            submitText={__(T.misc.login)}
          />
        </RightContainer>
      </Container>
    </Page>
  )
}

const TitleStyle = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 44px;
  line-height: 51px;
  padding-bottom: 63px;
  color: #222222;
`
