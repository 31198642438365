import { ApisauceConfig } from 'apisauce'
import api, { responseErrorCheck } from './api'

export default class Items {
  static endpoint = '/items'

  static batchDecode(epcs, requestConfig?: ApisauceConfig) {
    return api.post<any>(`${this.endpoint}/decode`, epcs, requestConfig).then(responseErrorCheck)
  }

  static decode(identifier: string, requestConfig?: ApisauceConfig) {
    return api
      .get<any>(`${this.endpoint}/decode?identifierCode=${identifier}`, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res)
  }
}
