import React, { Component } from 'react'
import styled from '@emotion/styled'
import { Box, KeyValueRowSkeleton } from 'components'
import { MotionStyle } from 'framer-motion'
import { Skeleton } from '@chakra-ui/react'
import EmptyList from './EmptyList'
import { Spacer } from './Various'

interface Props<T> {
  data: T[]
  title?: string
  renderItem: (item: T, index?: number) => JSX.Element
  titleStyle?: React.CSSProperties
  style?: React.CSSProperties
  loading?: boolean
}

export default class List<T> extends Component<Props<T>> {
  static Row = styled(Box)`
    flex-direction: row;
    align-items: center;
    background: #ffffff;
    padding: 20px 30px;
    /* border: 2px solid #edecec; */
    min-height: 103px;
    margin-bottom: 26px;
    cursor: pointer;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  `

  static Cell({ label, value, style }: { label?: string; value?: string; style?: MotionStyle }) {
    return (
      <Box flex style={style} mr={10}>
        {label && (
          <Box height={24} style={{ fontWeight: 700, fontSize: 16, marginBottom: 5 }}>
            {label}
          </Box>
        )}
        <Box style={{ fontSize: 20, fontWeight: 400 }}>{value ?? '---'}</Box>
      </Box>
    )
  }

  render() {
    const { title, renderItem, data, style, titleStyle, loading = false } = this.props
    return (
      <Box flex style={style}>
        {title && <Title style={titleStyle}>{title}</Title>}
        {loading &&
          [...Array(3)].map((o, i) => (
            <>
              <SkeletonContainer key={i}>
                <Box style={{ alignItems: 'center', justifyContent: 'space-between' }} pl={50} pr={30} height={110} row>
                  <KeyValueRowSkeleton />
                  <KeyValueRowSkeleton />
                  <KeyValueRowSkeleton />
                  <KeyValueRowSkeleton />
                  <KeyValueRowSkeleton />
                  <Skeleton startColor="#E6E6E6" endColor="#c8c8c8" width="55px" height="39px" />
                </Box>
              </SkeletonContainer>
              <Spacer />
            </>
          ))}

        {!loading && data?.length > 0 && (
          <Box>
            {data.map((d, index) => (
              <Box key={index}>{renderItem(d, index)}</Box>
            ))}
          </Box>
        )}

        {!loading && data?.length === 0 && <EmptyList />}
      </Box>
    )
  }
}

const Title = styled.div`
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 20px;
  color: rgba(79, 79, 79, 1);
  line-height: 23px;
`

const SkeletonContainer = styled(Box)`
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
`
