import React, { Component } from 'react'
import styled from '@emotion/styled'
import { Box, Image } from 'components'
import { TmrItem } from 'api/types'
import Products from 'api/Products'
import { T, __ } from 'translations/i18n'

interface Props {
  item: TmrItem
}

export default class ItemRow extends Component<Props> {
  render() {
    let { serial, product } = this.props.item
    return (
      <Row row>
        <ProductImage src={Products.getImageUrl(product?.code)} alt="item" />
        <Box style={{ marginLeft: 20 }}>
          <Title>{serial}</Title>
          <Title>{product.barcodeBC}</Title>
          <Label style={{ marginBottom: 10 }}>{product.description ?? '-'}</Label>
          <Label>
            {__(T.misc.material)}: <Value>{product.material?.value ?? '-'}</Value>
          </Label>
          <Label>
            {__(T.misc.size)}: <Value>{product.size?.valueDescription ?? '-'}</Value>
          </Label>
          <Label>
            {__(T.misc.color)}: <Value>{product.color?.value ?? '-'}</Value>
          </Label>
        </Box>
      </Row>
    )
  }
}

const Row = styled(Box)`
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 0.6px solid #d5d5d5;
`

const ProductImage = styled(Image)`
  width: 100px;
  height: 100px;
  padding: 10px;
`

const Title = styled.div`
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  color: #4f4f4f;
`
const Label = styled.div`
  color: #4f4f4f;
  font-weight: 300;
  font-size: 17px;
  line-height: 20px;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Value = styled(Label)`
  font-weight: 700;
  margin: 0px;
  margin-left: 5px;
`
