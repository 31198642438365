import { Component } from "react";
import { ReactComponent as Barcode } from "assets/img/icon-barcode.svg";
import { ReactComponent as Play } from "assets/img/icon-play.svg";
import { ReactComponent as AnimatedBar } from "assets/img/animated-bar.svg";
import { ReactComponent as Encoding } from "assets/img/icon-encoding.svg";
import { ReactComponent as Retry } from "assets/img/icon-retry.svg";
import { ReactComponent as Nfc } from "assets/img/nfc-icon.svg";
import { ReactComponent as Antenna } from "assets/img/antenna-icon.svg";
import { ReactComponent as Fullscreen } from "assets/img/icon-fullscreen.svg";
import { ReactComponent as Search } from "assets/img/icon-search.svg";
import { ReactComponent as AntennaOn } from "assets/img/antenna-on-icon.svg";
import { ReactComponent as Settings } from "assets/img/icon-settings.svg";
import { ReactComponent as ArrowDown } from "assets/img/arrow-down-icon.svg";
import { ReactComponent as Inbound } from "assets/img/icon-inbound.svg";
import { ReactComponent as TagInfo } from "assets/img/icon-tag-info.svg";
import { ReactComponent as CheckGreen } from "assets/img/check-green-icon.svg";
import { ReactComponent as LeftArrow } from "assets/img/left-arrow.svg";
import { ReactComponent as Info } from "assets/img/icon-info.svg";
import { ReactComponent as Close } from "assets/img/close-icon.svg";
import { ReactComponent as LeftArrow1 } from "assets/img/icon-left-arrow.svg";
import { ReactComponent as Loader } from "assets/img/loader-black.svg";
import { ReactComponent as Rfid } from "assets/img/rfid-icon.svg";
import { ReactComponent as Close1 } from "assets/img/close.svg";
import { ReactComponent as Outbound } from "assets/img/icon-outbound.svg";
import { ReactComponent as Confirmed } from "assets/img/confirmed-icon.svg";
import { ReactComponent as Loader1 } from "assets/img/loader.svg";
import { ReactComponent as Pause } from "assets/img/icon-pause.svg";
import { ReactComponent as RfidStation } from "assets/img/logo-stylewhere-rfid-station.svg";
import { ReactComponent as Serial } from "assets/img/serial-icon.svg";
import { ReactComponent as TransferToZone } from "assets/img/transfer-to-zone-icon.svg";
import { ReactComponent as Transfers } from "assets/img/icon-transfers.svg";
import { ReactComponent as HelpOutline } from "assets/img/help-outline.svg";
import { ReactComponent as CheckFilled } from "assets/img/check-filled.svg";

export default class Icons extends Component {
  static Barcode = Barcode;
  static Play = Play;
  static AnimatedBar = AnimatedBar;
  static Encoding = Encoding;
  static Retry = Retry;
  static Nfc = Nfc;
  static Antenna = Antenna;
  static Fullscreen = Fullscreen;
  static Search = Search;
  static AntennaOn = AntennaOn;
  static Settings = Settings;
  static ArrowDown = ArrowDown;
  static Inbound = Inbound;
  static TagInfo = TagInfo;
  static CheckGreen = CheckGreen;
  static LeftArrow = LeftArrow;
  static Info = Info;
  static Close = Close;
  static LeftArrow1 = LeftArrow1;
  static Loader = Loader;
  static Rfid = Rfid;
  static Close1 = Close1;
  static Outbound = Outbound;
  static Confirmed = Confirmed;
  static Loader1 = Loader1;
  static Pause = Pause;
  static RfidStation = RfidStation;
  static Serial = Serial;
  static TransferToZone = TransferToZone;
  static Transfers = Transfers;
  static Help = HelpOutline;
  static CheckFilled = CheckFilled;
}
