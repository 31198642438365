import React, { useEffect, useState } from "react";
import AppStore from "AppStore";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { ThemeProvider, Theme } from "@emotion/react";
import { PublicRoutes, RegisteredRoutes } from "pages";
import { history } from "shared/router";
import { hot } from "react-hot-loader/root";
import { changeLanguage } from "translations/i18n";
import { Box, ConfirmModal } from "components";
import { ChakraProvider, Spinner } from "@chakra-ui/react";

const theme: Theme = {
  background1: "#F2F2F2",
  background2: "#FFFFFF",
  primary: "#CEFF00",
  secondary: "#222222",
  padding: "20px",
  bgGrey: "#FFFFFF",
};

function App() {
  const [loading, setloading] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    async function init() {
      changeLanguage("it");

      await AppStore.loadInitalData();
      AppStore.toggleConfirmModalHandler = (show) => setShowConfirm(show);
      setloading(false);
    }
    init();
  }, []);
  if (loading)
    return (
      <ChakraProvider>
        <ThemeProvider theme={theme}>
          <Box flex center vcenter>
            <Spinner thickness="5px" speed="0.65s" color="#e0e0e0" size="xl" />
          </Box>
        </ThemeProvider>
      </ChakraProvider>
    );

  const authenticated = !!AppStore.loggedUser;

  return (
    <ChakraProvider>
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <Switch>
            {!authenticated &&
              Object.entries(PublicRoutes).map(([path, route]) => {
                return (
                  <Route
                    path={path}
                    component={route.component}
                    key={path}
                    exact
                  />
                );
              })}

            {authenticated &&
              Object.entries(RegisteredRoutes).map(([path, route]) => {
                return (
                  <Route
                    path={path}
                    component={route.component}
                    key={path}
                    exact
                  />
                );
              })}
            <Redirect to={authenticated ? "/" : "/login"} />
          </Switch>
        </Router>

        {showConfirm && (
          <ConfirmModal
            title={AppStore.confirmModalParams.title}
            subtitle={AppStore.confirmModalParams.message}
            onClose={() => {
              AppStore.confirmModalParams?.onCancel?.();
              setShowConfirm(false);
            }}
            onConfirm={() => {
              AppStore.confirmModalParams?.onConfirm();
              setShowConfirm(false);
            }}
          />
        )}
      </ThemeProvider>
    </ChakraProvider>
  );
}

export default hot(App);
