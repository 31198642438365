import React, { useEffect, useState } from 'react'
import { Page, Box, Image, Button, Modal, List, ItemRow, SettingsModal, Icons } from 'components'
import styled from '@emotion/styled'
import AppStore from 'AppStore'
import { showToast } from 'shared/utils'
import { Text } from '@chakra-ui/layout'
import config from 'config/config'
import Items from 'api/Items'
import { T, __ } from 'translations/i18n'

export default function Dashboard() {
  const [detailModalVisible, setDetailModalVisible] = useState(false)
  const [items, setItems] = useState([])
  const [epcs, setEpcs] = useState<string[]>([])
  const [scanners, setScanners] = useState<WebSocket[]>([])
  const [settingsModalVisible, setsettingsModalVisible] = useState(false)

  useEffect(() => {
    initScanners()
  }, [])

  const onTagRead = (epc: string) => {
    if (epcs.includes(epc)) return
    let newEpcs = [...epcs]
    newEpcs.push(epc)
    setEpcs(newEpcs)
  }

  const onError = (scanner) => {
    showToast({ title: 'Errore ' + scanner, status: 'error' })
  }

  const onClose = (scanner) => {
    showToast({ title: `Connessione con scanner ${scanner} chiusa`, status: 'warning' })
  }

  const initScanners = async () => {
    let socketArray = AppStore.scanners.map((scanner) => {
      let webSocket = new WebSocket(config.endpoint.replace('https', 'ws') + 'desknotice/' + scanner)
      webSocket.onmessage = (ev) => onTagRead(ev.data)
      webSocket.onerror = (ev) => onError(scanner)
      webSocket.onclose = (ev) => onClose(scanner)
      return webSocket
    })
    setScanners(socketArray)
  }

  const onDetailClick = async () => {
    let items = Object.values(await Items.batchDecode(epcs))
    setItems(items as any)
    setDetailModalVisible(true)
  }

  const resetData = () => {
    setItems([])
    setEpcs([])
  }

  let placeDescription = AppStore.loggedUser?.place.description ?? '-'
  return (
    <Page title="Dashboard" header={null}>
      <Box flex>
        <Box row vcenter style={{ marginTop: 32, marginLeft: 37 }}>
          <Text>{placeDescription}</Text>
          <Exit onClick={() => AppStore.logout()}>{__(T.titles.exit)}</Exit>
          <Box flex />
          <BottomButton onClick={() => setsettingsModalVisible(true)}>
            <Icons.Settings style={{ width: 36, margin: 17, opacity: 0.8 }} />
          </BottomButton>
        </Box>
        <Box flex center vcenter>
          {epcs.length > 0 && (
            <>
              <Image style={{ maxHeight: 155, maxWidth: 124, marginBottom: 51 }} alt="" src="assets/img/error.png" />
              <Title color={'black'}>{__(T.titles.notSelledProduct)}</Title>
              <Box flex={0} row style={{ marginTop: 65 }}>
                <Button onClick={resetData} variant={'default'} style={{ marginLeft: 20, minWidth: 199 }}>
                  {__(T.misc.reset)}
                </Button>
                <Button onClick={onDetailClick} variant={'secondary'} style={{ marginLeft: 20, minWidth: 199 }}>
                  {__(T.misc.detail)}
                </Button>
              </Box>
            </>
          )}
          {epcs.length == 0 && (
            <>
              <Image
                style={{ maxHeight: 155, maxWidth: 124, marginBottom: 51 }}
                alt=""
                src="assets/img/notification.png"
              />
              <Title>{__(T.titles.waitingNotification)}</Title>
            </>
          )}
        </Box>
      </Box>
      <SettingsModal onClose={() => setsettingsModalVisible(false)} visible={settingsModalVisible} />
      <Modal
        size={'md'}
        title={__(T.titles.itemFound)}
        onClose={() => setDetailModalVisible(false)}
        visible={detailModalVisible}
      >
        <Container>
          <List style={{ maxHeight: '60vh' }} data={items} renderItem={(item) => <ItemRow item={item} />} />
        </Container>
      </Modal>
    </Page>
  )
}

const Container = styled(Box)`
  border-top: 0.5px solid #d5d5d5;
  overflow: auto;
  margin-top: 20px;
`

const Title = styled.h1`
  font-style: normal;
  font-size: 38px;
  line-height: 44, 53px;
  color: ${({ color }) => color ?? '#b1b4bd'};
  text-align: center;
`

const Exit = styled.span`
  margin-left: 10px;
  color: #e22222;
  cursor: pointer;
  text-decoration: underline;
`

const BottomButton = styled.div`
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  cursor: pointer;
`
