import React, { Component } from 'react'
import { Box, Button, Input, KeyValueRow, Modal } from 'components'
import { T, __ } from 'translations/i18n'
import { Spacer } from 'components/Various'
import AppStore from 'AppStore'
import { refresh } from 'shared/router'
import { sleep, __isDev } from 'shared/utils'
import { Switch } from 'components/Switch'

interface Props {
  onClose: () => void
  visible: boolean
}

interface State {
  admin: boolean
  endpoint: string
}

export default class SettingsModal extends Component<Props, State> {
  languages = [
    { label: 'English', value: 'en' },
    { label: 'Italiano', value: 'it' },
  ]
  state: State = { admin: false, endpoint: '' }

  handleChange = (item: any) => {
    AppStore.changeLanguage(item.value)

    sleep(100).then(() => refresh())
  }

  async componentDidMount() {
    this.setState({ endpoint: await AppStore.getEndpoint() })
  }

  render() {
    const { onClose, visible } = this.props
    const { admin, endpoint } = this.state
    return (
      <Modal size="lg" onClose={onClose} visible={visible}>
        <Box vcenter row mb={30}>
          <Modal.Title>{__(T.titles.settings)}</Modal.Title>
        </Box>
        {!admin && <KeyValueRow variant="grey" label="Backend" value={endpoint} />}
        {admin && <Input label="Backend" onEnter={AppStore.setEndpoint} defaultValue={endpoint} />}

        <Spacer />

        <KeyValueRow
          variant="grey"
          label={__(T.misc.language)}
          value={this.languages.find((language) => language.value === AppStore.language)?.label}
          optionModal={{
            onSelect: (item) => this.handleChange(item[0]),
            field: 'label',
            options: this.languages ?? [],
            title: __(T.titles.select_language),
            selected: [{ label: this.languages.find((language) => language.value === AppStore.language)?.label }],
          }}
        />
      </Modal>
    )
  }
}
